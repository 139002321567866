<template>
  <div class="d-flex align-center justify-center fill-height">
    <div>
      <h1>Not found</h1>
      <h3>The requested resource was not found</h3>
      <a :href="url">
        <h2>Go to home page</h2>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
  computed: {
    url () {
      return process.env.VUE_APP_HOME_PAGE
    }
  }
}
</script>
<style>
a:link {
  color: var(--v-unvisitedLink-base);
}
a:visited {
  color: var(--v-visitedLink-base);
}
</style>
